import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ApiSidebar from "../../components/api-sidebar"
import ApiDocItem from "../../components/api-doc-item"
import ApiDocFooter from "../../components/api-doc-footer"
import ApiParameterTable from "../../components/api-parameter-table"
import ApiKeyField from "../../components/api-key-field"
import CodeBlock from "../../components/code-block"
import CodeTransformer from "../../components/code-transformer"

import apiSettings from "../../utils/api-settings"
import Transformer from "../../utils/code-transformer/transformer"

const endpoint = "play/clip"

const transformer = new Transformer({
  method: "post",
  options: {
    endpoint: endpoint,
    apiBaseUrl: apiSettings.apiBaseUrl,
    apiKey: apiSettings.apiKey,
  },
})

const ApiPlayClipPage = () => (
  <Layout sidebar={ApiSidebar()} title="Play Clip API">
    <SEO title="Play Clip API" />
    <p>
      Use the <i>Play Clip API</i> to play custom audio notifications on your
      Sonos speakers. Just replace <code>{apiSettings.apiKeyPlaceholder}</code>{" "}
      with your own key. Clips are audio files you uploaded or saved before with
      other Quak <Link to="/clients/">Clients</Link>.
    </p>

    <p>
      To use the <i>Play Clip API</i> you need credits. One credit is necessary
      to play a single custom clip.{" "}
      <Link to="/pricing/">Learn more about credits here.</Link> No credits are
      charged when previewing notifications.
    </p>

    <ApiKeyField />

    <ApiDocItem>
      <h3>Endpoint</h3>
      <CodeBlock
        platform="bash"
        code={{
          bash: {
            code: `POST  ${apiSettings.apiBaseUrl}/${endpoint}`,
            codeToCopy: `${apiSettings.apiBaseUrl}/${endpoint}`,
          },
        }}
        showPlatformPicker={false}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Basic example</h3>
      <p>
        This will play the defined clip with your default settings on your Sonos
        speakers. <code>clip</code> is the only required field.{" "}
        <Link to="/api/list-clips/">Get a list of all available clips.</Link>
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            clip: "my-custom-clip",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Set volume & speakers</h3>
      <p>
        Set <code>targets</code> (speakers, groups, ...) and <code>volume</code>{" "}
        to override your global defaults.{" "}
        <Link to="/api/list-targets/">
          Get a list of all available targets.
        </Link>
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            clip: "my-custom-clip",
            targets: "livingroom",
            volume: 10,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play on multiple targets</h3>
      <p>
        To play on multiple targets at the same time, set the{" "}
        <code>targets</code> parameters like this.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            clip: "my-custom-clip",
            targets: ["livingroom", "bath"],
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Preview mode</h3>
      <p>
        If you just want to preview the playback, you can pass{" "}
        <code>preview</code> and nothing will be played on your Sonos speakers.
        But the JSON response will include an URL to the audio file.
      </p>
      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            clip: "my-custom-clip",
            preview: true,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Play only on specific days and times</h3>
      <p>
        Use the <code>days</code> and <code>hours</code> parameters to specify
        when to play the clip. If week day and time (
        <a href="https://app.quak.sh/settings" target="_blank">
          check your time zone
        </a>
        ) does not match nothing will be played. Notifications will not be
        queued.
      </p>
      <p>
        Let's say you don't want to play notifications in the night because...
        well... you are sleeping. It would be a waste of energy and Quak credits
        if the clip would be played 24/7.
      </p>

      <CodeTransformer
        transformer={transformer}
        options={{
          data: {
            clip: "my-custom-clip",
            days: "mo-sa",
            hours: "8-23",
          },
        }}
      />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Request Parameters</h3>
      <p>This is a full list of the request parameters you can use:</p>
      <ApiParameterTable type="CLIP" />
    </ApiDocItem>

    <ApiDocItem>
      <h3>Example Response</h3>
      <p>
        If the request was successful you will receive a response like this one.
        The <code>uuid</code> is the unique identifier you can use to get
        updates regarding playback status.
      </p>
      <p>
        {" "}
        Directly after submitting <code>status</code> is{" "}
        <code className="value">PENDING</code>. Only if you use{" "}
        <code>preview</code> the <code>status</code> is{" "}
        <code className="value">DONE</code>.{" "}
        <Link to="/api/response-codes/">Learn more about response codes.</Link>
      </p>
      <CodeBlock
        platform="json"
        code={{
          json: {
            code: `{
  "uuid": "4058c6d8-7576-4bd1-a834-c052305b4c60",
  "createdAt": "2019-05-23T12:51:50.010Z",
  "type": "CLIP",
  "status": "PENDING",
  "audioUrl": "https://quak.fra1.digitaloceanspaces.com/clips/7873/ffa9/7873ffa9-ec9f-46bb-ba78-b8b267e51a32.mp3?AWSAccessKeyId=EGPNSIGBEVMODYAKNRLK&Expires=1558616090&Signature=jWGEzTznKqUFt6BP8lKc7PrHDKM%3D",
  "audioId": "7873ffa9-ec9f-46bb-ba78-b8b267e51a32",
  "audioUrlExpiresAt": null,
  "length": 2.38,
  "credits": 1,
  "fromCache": true,
  "players": [
    {
      "uuid": "f89fcd4d-05d1-4231-a954-ca5f5cead5a1",
      "slug": "livingroom",
      "name": "Livingroom",
      "provider": "SONOS",
      "status": "PENDING"
    }
  ],
  "params": {
    "volume": 30,
    "targets": [
      "livingroom"
    ],
    "preview": false,
    "priority": false,
    "clip": "my-custom-clip"
  },
  "clip": {
    "uuid": "5f539278-d036-4314-8501-4bb7bfe90fff",
    "slug": "my-custom-clip",
    "name": "My Custom Clip"
  }
}`,
          },
        }}
      />
    </ApiDocItem>

    <ApiDocFooter active="/api/play-clip/" />
  </Layout>
)

export default ApiPlayClipPage
